import { createRouter, createWebHistory } from 'vue-router';
import { isExternalUrl } from '@/core/helpers/url-helpers';
import storage from '@/core/helpers/storage';
import paths from '@/router/paths';

// paths and routes for each feature
import { accountRoutes } from '@/features/account/routes';
import { acelPaths, acelRoutes } from '@/features/acel/routes';
import { authPaths, authRoutes } from '@/features/auth/routes';
import { commuteBoostRoutes } from '@/features/commute-boost/routes';
import { commuteIQRoutes } from '@/features/commute-iq/routes';
import { adminRoutes } from '@/features/admin/routes';

import _ from 'underscore';
import NProgress from 'nprogress';
import {
  ROLE_ACEL,
  ROLE_ADMIN,
  ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
  ROLE_CONTACTS,
  ROLE_CONTACTS_ADMIN,
  ROLE_SCOPING,
  ROLE_SUPERADMIN,
  ROLE_SURVEY,
  ROLE_TRAVELPLAN,
  ROLE_TRAVELPLAN_ADMIN,
} from '@/stores/global-constants';
import { useAuthStore } from '@/stores/auth/auth-store';
// TODO: Split routes into separate files for each tab of the site
import { Layouts as Layout } from '@/features/navigation/enums';

const routes = [
  ...accountRoutes,
  ...acelRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...commuteBoostRoutes,
  ...commuteIQRoutes,
  {
    path: paths.help.path,
    name: paths.help.name,
    component: () => import('@/views/Error/Help.vue'),
    props: (route: any) => ({ code: route.query.code }),
    meta: {
      title: 'Help',
    },
  },
  {
    path: paths.survey.path,
    name: paths.survey.name,
    component: () => import('@/views/Survey/Index.vue'),
    meta: {
      title: 'Surveys',
      rolesToPermit: ROLE_SURVEY,
      nextIfUnauthorized: paths.surveyDisabled.path,
    },
  },
  {
    path: paths.surveyDisabled.path,
    name: paths.surveyDisabled.name,
    component: () => import('@/views/Survey/Disabled.vue'),
    meta: {
      title: 'Surveys / Disabled',
    },
  },
  {
    path: paths.surveyCreate.path,
    name: paths.surveyCreate.name,
    component: () => import('@/views/Survey/Create/Index.vue'),
    alias: ['/survey/create'],
    meta: {
      title: 'Survey / Create/Edit',
      rolesToPermit: ROLE_SURVEY,
      nextIfUnauthorized: paths.surveyDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.surveyAnalysis.path,
    name: paths.surveyAnalysis.name,
    component: () => import('@/features/surveys/screens/Analytics.vue'),
    meta: {
      title: 'Survey / Analysis',
      rolesToPermit: ROLE_SURVEY,
      nextIfUnauthorized: paths.surveyDisabled.path,
    },
  },
  {
    path: paths.scoping.path,
    name: paths.scoping.name,
    component: () => import('@/views/Scoping/Index.vue'),
    meta: {
      title: 'Scoping',
      rolesToPermit: ROLE_SCOPING,
      nextIfUnauthorized: paths.scopingDisabled.path,
    },
  },
  {
    path: paths.scopingFull.path,
    name: paths.scopingFull.name,
    component: () => import('@/views/Scoping/Admin_Scoping_Summary/Index.vue'),
    meta: {
      title: 'Scoping / Admin',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.scoping.path,
    },
  },
  {
    path: paths.scopingMap.path,
    name: paths.scopingMap.name,
    component: () => import('@/views/Scoping/Map.vue'),
    meta: {
      title: 'Scoping / Map',
      allowAnonymous: true,
    },
  },
  {
    path: paths.scopingProcessing.path,
    name: paths.scopingProcessing.name,
    component: () => import('@/views/Scoping/Processing.vue'),
    meta: {
      title: 'Scoping / Processing',
      rolesToPermit: ROLE_SCOPING,
      nextIfUnauthorized: paths.scopingDisabled.path,
    },
  },
  {
    path: paths.scopingDisabled.path,
    name: paths.scopingDisabled.name,
    component: () => import('@/views/Scoping/Disabled.vue'),
    meta: {
      title: 'Scoping / Disabled',
    },
  },
  {
    path: paths.behaviourChange.path,
    name: paths.behaviourChange.name,
    component: () => import('@/views/BehaviourChange/Index.vue'),
    meta: {
      title: 'Behaviour change',
    },
  },
  {
    path: paths.termsAndConditions.path,
    name: paths.termsAndConditions.name,
    component: () => import('@/views/Static/TermsAndConditions.vue'),
    meta: {
      title: 'Terms',
      allowAnonymous: true,
    },
  },
  // {
  //   path: paths.privacyPolicy.path,
  //   name: paths.privacyPolicy.name,
  //   meta: {
  //     allowAnonymous: true,
  //   },
  //   component: () => import( '@/views/Static/PrivacyPolicy.vue'),
  // },
  {
    path: paths.cookieInformation.path,
    name: paths.cookieInformation.name,
    meta: {
      title: 'Cookies',
      allowAnonymous: true,
    },
    component: () => import('@/views/Static/CookieInformation.vue'),
  },
  {
    path: paths.acelAssumptions.path,
    name: paths.acelAssumptions.name,
    component: () => import('@/views/Static/AcelAssumptions.vue'),
    meta: {
      title: 'ACEL / Assumptions',
    },
  },
  {
    path: paths.contacts.path,
    name: paths.contacts.name,
    component: () => import('@/views/Contacts/Index.vue'),
    meta: {
      title: 'Contacts',
      rolesToPermit: ROLE_CONTACTS,
      nextIfUnauthorized: paths.contactsDisabled.path,
    },
  },
  {
    path: paths.contactsDisabled.path,
    name: paths.contactsDisabled.name,
    component: () => import('@/views/Contacts/Disabled.vue'),
    meta: {
      title: 'Contacts / Disabled',
    },
  },
  {
    path: paths.contactsImport.path,
    name: paths.contactsImport.name,
    component: () => import('@/views/Contacts/Import.vue'),
    meta: {
      title: 'Contacts / Import',
      rolesToPermit: ROLE_CONTACTS_ADMIN,
      nextIfUnauthorized: paths.contactsDisabled.path,
    },
  },
  {
    path: paths.contactsPreviousImports.path,
    name: paths.contactsPreviousImports.name,
    component: () => import('@/views/Contacts/PreviousImports.vue'),
    meta: {
      title: 'Contacts / Previous imports',
      rolesToPermit: ROLE_CONTACTS,
      nextIfUnauthorized: paths.contactsDisabled.path,
    },
  },
  {
    path: paths.LowFrequencyAssumptions.path,
    name: paths.LowFrequencyAssumptions.name,
    component: () => import('@/views/Static/LowFrequencyAssumptions.vue'),
    meta: {
      title: 'Low Frequency / Assumptions',
    },
  },
  {
    path: paths.travelplansDisabled.path,
    name: paths.travelplansDisabled.name,
    component: () => import('@/views/TravelPlan/Disabled.vue'),
    meta: {
      title: 'PTP / Disabled',
    },
  },
  {
    path: paths.travelplans.path,
    name: paths.travelplans.name,
    component: () => import('@/views/TravelPlan/Dashboard/Index.vue'),
    meta: {
      title: 'PTP',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.travelPlanList.path,
    name: paths.travelPlanList.name,
    component: () => import('@/views/TravelPlan/Plans/Index.vue'),
    meta: {
      title: 'PTP / Plans',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.travelplansSurvey.path,
    name: paths.travelplansSurvey.name,
    component: () => import('@/views/TravelPlan/SurveyResults/index.vue'),
    meta: {
      title: 'PTP / Survey results',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.travelplansWidget.path,
    name: paths.travelplansWidget.name,
    component: () => import('@/views/TravelPlan/Widgets.vue'),
    meta: {
      title: 'PTP / Widgets',
      rolesToPermit: ROLE_TRAVELPLAN, // ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.travelplansTexts.path,
    name: paths.travelplansTexts.name,
    component: () => import('@/views/TravelPlan/CustomTexts/Index.vue'),
    meta: {
      title: 'PTP / Custom texts',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.travelplansPlanEmail.path,
    name: paths.travelplansPlanEmail.name,
    component: () => import('@/views/TravelPlan/PlanEmail.vue'),
    meta: {
      title: 'PTP / Plan email',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.travelplansBatchEmail.path,
    name: paths.travelplansBatchEmail.name,
    component: () => import('@/views/TravelPlan/PlanEmail.vue'),
    meta: {
      title: 'PTP / Batch email',
      rolesToPermit: ROLE_TRAVELPLAN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.travelplansEditPlan.path,
    name: paths.travelplansEditPlan.name,
    component: () => import('@/views/TravelPlan/CreatePlan/Index.vue'),
    alias: [paths.travelplansCreatePlan.path],
    meta: {
      title: 'PTP / Create plan',
      rolesToPermit: ROLE_TRAVELPLAN_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.travelplansWidgetsNew.path,
    name: paths.travelplansWidgetsNew.name,
    component: () => import('@/views/TravelPlan/CreateWidget/Create.vue'),
    meta: {
      title: 'Travel plans / Create widget',
      rolesToPermit: ROLE_TRAVELPLAN_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
      layout: Layout.Private,
    },
  },
  {
    path: paths.travelplansEditWidget.path,
    name: paths.travelplansEditWidget.name,
    component: () => import('@/views/TravelPlan/CreateWidget/Index.vue'),
    alias: [paths.travelplansCreateWidget.path],
    meta: {
      title: 'PTP / Edit widget',
      rolesToPermit: ROLE_TRAVELPLAN_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.pendingLiftshareAccounts.path,
    name: paths.pendingLiftshareAccounts.name,
    component: () => import('@/views/PendingLiftshareAccounts/Index.vue'),
    meta: {
      title: 'Pending Liftshare accounts',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.pendingLiftshareAccountsDisabled.path,
    },
  },
  {
    path: paths.pendingLiftshareAccountsCreate.path,
    name: paths.pendingLiftshareAccountsCreate.name,
    component: () => import('@/views/PendingLiftshareAccounts/Create/Index.vue'),
    meta: {
      title: 'Pending Liftshare accounts / Create',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.pendingLiftshareAccountsDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.pendingLiftshareAccountsEdit.path,
    name: paths.pendingLiftshareAccountsEdit.name,
    component: () => import('@/views/PendingLiftshareAccounts/Create/Index.vue'),
    meta: {
      title: 'Pending Liftshare accounts / Edit',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.pendingLiftshareAccountsDisabled.path,
      layout: Layout.PrivateEmpty,
    },
  },
  {
    path: paths.pendingLiftshareAccountsDisabled.path,
    name: paths.pendingLiftshareAccountsDisabled.name,
    component: () => import('@/views/PendingLiftshareAccounts/Disabled.vue'),
    meta: {
      title: 'Pending Liftshare accounts / Disabled',
    },
  },
  {
    path: paths.adminPendingLiftshare.path,
    name: paths.adminPendingLiftshare.name,
    component: () => import('@/views/Admin/PendingLiftshareAccounts/Index.vue'),
    meta: {
      title: 'Pending Liftshare accounts / Admin',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.adminPendingLiftshareNew.path,
    name: paths.adminPendingLiftshareNew.name,
    component: () => import('@/views/Admin/PendingLiftshareAccounts/New.vue'),
    meta: {
      title: 'Pending Liftshare accounts / Admin new',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.Liftshare.path,
    name: paths.Liftshare.name,
    beforeEnter() {
      window.open('https://dashboard.liftshare.com/', '_blank');
      return false;
    },
  },
  {
    path: paths.adminNotifications.path,
    name: paths.adminNotifications.name,
    component: () => import('@/views/Admin/Notifications/Notifications.vue'),
    meta: {
      title: 'Admin / Notifications',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.adminPlatformUsage.path,
    name: paths.adminPlatformUsage.name,
    component: () => import('@/views/Admin/PlatformUsage/index.vue'),
    meta: {
      title: 'Admin / Platform usage',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  {
    path: paths.adminNotificationEmailPreview.path,
    name: paths.adminNotificationEmailPreview.name,
    component: () => import('@/views/Admin/Notifications/EmailPreview.vue'),
    meta: {
      title: 'Admin / Notifications / Email preview',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.travelplansDisabled.path,
    },
  },
  // Liftshare
  // {
  //   path: paths.pendingLiftshareAccounts.path,
  //   name: paths.pendingLiftshareAccounts.name,
  //   component: () =>
  //     import( '@/views/Liftshare/PendingLiftshareAccounts.vue'),
  //   meta: {
  //     rolesToPermit: ROLE_LIFTSHARE_ADMIN,
  //     nextIfUnauthorized: paths.travelplansDisabled.path,
  //   },
  // },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/Error/NotFound.vue'),
    meta: {
      title: '404 not found',
    },
  },
  {
    path: paths.dashboardHighFrequency.path,
    name: paths.dashboardHighFrequency.name,
    component: () => import('@/views/Dashboards/HighFrequencyDashboard.vue'),
    meta: {
      title: 'High-frequency dashboard',
      rolesToPermit: ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.dashboardLowFrequency.path,
    name: paths.dashboardLowFrequency.name,
    component: () => import('@/views/Dashboards/LowFrequencyDashboard.vue'),
    meta: {
      title: 'Low-frequency dashboard',
      rolesToPermit: [...ROLE_ACEL, ...ROLE_COMMUTE_ACTIVITY_DASHBOARD_ADMIN],
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.adminHF.path,
    name: paths.adminHF.name,
    component: () => import('@/views/Admin/high-frequency/Index.vue'),
    meta: {
      title: 'High-frequency admin dashboard',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.adminSurveyListAll.path,
    name: paths.adminSurveyListAll.name,
    component: () => import('@/views/Admin/surveys/AllSurveysListPage.vue'),
    meta: {
      title: 'All surveys',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: paths.surveyDisabled.path,
      layout: Layout.Private,
    },
  },
  {
    path: paths.adminTracking.path,
    name: paths.adminTracking.name,
    component: () => import('@/views/Admin/tracking/Index.vue'),
    meta: {
      title: 'Tracking',
      rolesToPermit: ROLE_SUPERADMIN,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.adminGHGFactorSets.path,
    name: paths.adminGHGFactorSets.name,
    component: () => import('@/views/Admin/ghg-factor-sets/Index.vue'),
    meta: {
      title: 'GHG factor sets',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.dashboardLiftshare.path,
    name: paths.dashboardLiftshare.name,
    component: () => import('@/views/Dashboards/LiftshareDashboard.vue'),
    meta: {
      title: 'Liftshare dashboard',
      rolesToPermit: ROLE_ACEL,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.dashboardZeelo.path,
    name: paths.dashboardZeelo.name,
    component: () => import('@/views/Dashboards/ZeeloDashboard.vue'),
    meta: {
      title: 'Zeelo dashboard',
      rolesToPermit: ROLE_ACEL,
      nextIfUnauthorized: acelPaths.acelDisabled.path,
    },
  },
  {
    path: paths.designSystem.path,
    name: paths.designSystem.name,
    component: () => import('@/views/DesignSystem/DesignSystem.vue'),
    meta: {
      title: 'Design System',
      rolesToPermit: ROLE_ACEL,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  // @ts-expect-error
  routes,
});

// Global handler to redirect to home/login if authenticated/signed out
router.beforeEach((to, from, next) => {
  NProgress.start();
  const authStore = useAuthStore();

  const isAuthenticated = authStore.isAuthenticated;

  // If attempting to access an authenticated page while signed out, redirect to login
  if (!to.meta.allowAnonymous && !isAuthenticated) {
    next({ name: authPaths.login.name });
    return;
  }

  // If we're already signed in, go to homepage instead of login
  // TODO: Cover all anon pages not just login
  if (to.name === authPaths.login.name && isAuthenticated) {
    next({ name: acelPaths.acel.name });
    return;
  }

  // If page allows anon, skip roles check and just go there
  if (to.meta.allowAnonymous) {
    next();
    return;
  }

  // Resume regular route, checking for role access first
  permitRole(to, next);
});

router.afterEach(() => {
  NProgress.done();
});

// permit a certain role to view this page, if not redirect
// @ts-expect-error
const permitRole = (to, next: (route?: string) => void) => {
  // @ts-ignore
  const userToken = storage.get('token'); //store.getters['auth/getUserToken'];
  // @ts-expect-error
  const tokenData = JSON.parse(atob(userToken.split('.')[1]));
  const currentUserRoles = _.pluck(tokenData.CommunityRole, 'Role');
  const { rolesToPermit, nextIfUnauthorized } = to.meta;

  //No token then definitely not authorised!
  if (!tokenData) {
    if (isExternalUrl(nextIfUnauthorized)) {
      window.location = nextIfUnauthorized;
    } else {
      next(nextIfUnauthorized);
    }
  }

  let permittedRoles = [...ROLE_SUPERADMIN];

  if (Array.isArray(rolesToPermit)) {
    permittedRoles = [...permittedRoles, ...rolesToPermit];
  } else {
    permittedRoles.push(rolesToPermit);
  }

  // check user roles list contains at least one of the permitted roles
  if (
    permittedRoles.includes(tokenData.CommunityRole.Role) ||
    permittedRoles.some((e: string) => currentUserRoles.includes(e))
  ) {
    next();
  } else if (nextIfUnauthorized) {
    if (isExternalUrl(nextIfUnauthorized)) {
      window.location = nextIfUnauthorized;
    } else {
      next(nextIfUnauthorized);
    }
  }
};

export default router;
