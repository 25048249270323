import { ROLE_ADMIN } from '@/stores/global-constants';
import { Layouts as Layout } from '@/features/navigation/enums';
import { commuteIQPaths } from '@/features/commute-iq/routes';

export const adminPaths = {
  adminCommuteIQListAll: {
    path: '/admin/commute-iq/all',
    name: 'admin-commute-iq-list-all',
  },
  adminFinanceReporting: {
    path: '/admin/finance-reporting',
    name: 'admin-finance-reporting',
  },
};

export const adminRoutes = [
  // {
  //   path: adminPaths.adminCommuteIQListAll.path,
  //   name: adminPaths.adminCommuteIQListAll.name,
  //   component: () => import('@/features/commute-iq/ListPage.vue'),
  //   meta: {
  //     title: 'All Commute IQ reports',
  //     rolesToPermit: ROLE_ADMIN,
  //     nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
  //     layout: Layout.Private,
  //   },
  // },
  {
    path: adminPaths.adminFinanceReporting.path,
    name: adminPaths.adminFinanceReporting.name,
    component: () => import('@/features/admin/screens/FinanceReporting.vue'),
    meta: {
      title: 'Finance reporting',
      rolesToPermit: ROLE_ADMIN,
      nextIfUnauthorized: commuteIQPaths.commuteIQDisabled.path,
      layout: Layout.Private,
    },
  },
];
